import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faAt} from "@fortawesome/free-solid-svg-icons";
import {useEffect} from "react";

export const Contact = () => {

    useEffect(() => {
        document.title = "Wayward Cookies : Contact";
    });

    return (
        <div className={"container"}>
            <div className={"row py-5 d-flex justify-content-center"}>
                <div className={"col-md col-xl-5 p-3"}>
                    <img src={process.env.PUBLIC_URL + "/christina-contact.jpg"} alt={"Christina"}
                         width={"100%"}/>
                </div>
                <div className={"col-md col-xl-5 p-3 d-flex align-items-center justify-content-center"}>
                    <FontAwesomeIcon icon={faEnvelope} className={"pe-3"} size={"2xl"}/>
                    <a href="mailto:&#099;&#104;&#114;&#105;&#115;&#116;&#105;&#110;&#097;&#064;&#119;&#097;&#121;&#119;&#097;&#114;&#100;&#099;&#111;&#111;&#107;&#105;&#101;&#115;&#046;&#099;&#111;&#109;">
                        christina
                        <FontAwesomeIcon icon={faAt} className={"px-2"}/>
                        waywardcookies.com
                    </a>
                </div>
            </div>
        </div>
    )
}