export const orderStatuses = [
    {
        code: "SU",
        name: "Submitted",
        active: true
    },
    {
        code: "AC",
        name: "Accepted",
        active: true
    },
    {
        code: "WB",
        name: "Baked",
        active: true
    },
    {
        code: "WI",
        name: "Base Iced",
        active: true
    },
    {
        code: "WD",
        name: "Decorated",
        active: true
    },
    {
        code: "WP",
        name: "Packaged",
        active: true
    },
    {
        code: "SH",
        name: "Shipped",
        active: true
    },
    {
        code: "CM",
        name: "Complete",
        active: false
    },
    {
        code: "CA",
        name: "Cancelled",
        active: false
    },
    {
        code: "RE",
        name: "Rejected",
        active: false
    }
]

export const formatShortDateTime = (date) => {
    const options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    return new Date(date).toLocaleDateString('en-us', options);
}

export const formatMonthDay = (date) => {
    const options = {month: 'long', day: 'numeric'};
    return new Date(date).toLocaleDateString('en-us', options);
}

export const daysBetween = (dateA, dateB) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = Math.abs(dateB.getTime() - dateA.getTime());
    return Math.ceil(diffInTime / oneDay);
}

const availStateAbbr = [
    " ", "AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MP", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UM", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"
];

export const availableStateSelections = [
    availStateAbbr.map((strAbbr) =>
        <option key={strAbbr} value={strAbbr}>{strAbbr}</option>
    )
]

export const activeStatusCodes = () => {
    return orderStatuses.filter(status => status.active === true).map(status => status.code);
}

export const inactiveStatusCodes = () => {
    return orderStatuses.filter(status => status.active === false).map(status => status.code);
}

export const formatPhoneNumber = (phoneStr) => {
    const phoneStrStripped = phoneStr.replaceAll('.', '');
    if (phoneStrStripped.length < 4) {
        return phoneStrStripped;
    } else if (phoneStrStripped.length < 7) {
        return phoneStrStripped.slice(0, 3) + '.' + phoneStrStripped.slice(3);
    } else {
        return phoneStrStripped.slice(0, 3) + '.' + phoneStrStripped.slice(3, 6) + '.' + phoneStrStripped.slice(6);
    }
}