import {forwardRef, useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import "./customOrder.css";
import "react-datepicker/dist/react-datepicker.css";
import {formatPhoneNumber} from "../../util/helpers";
import {formatDateForRequest} from "../../util/waywardHttpHelper";

export const CustomOrder = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [orderDetails, setOrderDetails] = useState("");
    const [cookieQuantity, setCookieQuantity] = useState("");
    const [cookieFlavor, setCookieFlavor] = useState("");
    const [macaronQuantity, setMacaronQuantity] = useState("");
    const [macaronFlavor, setMacaronFlavor] = useState("");
    const [pickupDate, setPickupDate] = useState("");
    const [errors, setErrors] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const CustomDateInput = forwardRef(({value, onClick}, ref) => (
        <div className="form-floating">
            <input type="text" id="order-date" ref={ref} placeholder=" " onClick={onClick} readOnly={true}
                   className="form-control react-datepicker-ignore-onclickoutside" value={value}/>
            <label htmlFor="order-date">Pickup Date</label>
        </div>
    ));

    useEffect(() => {
        document.title = "Wayward Cookies : Custom Order";
    });

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (validateInput()) {
            submitForm();
        }
    }

    const handlePhoneNumber = (e) => {
        const allowedInput = /^[0-9\b.]+$/;
        if (e.target.value === '' || (allowedInput.test(e.target.value) && e.target.value.length <= 12)) {
            setPhoneNumber(formatPhoneNumber(e.target.value));
        }
    }

    const submitForm = () => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    phone: phoneNumber,
                    email: email,
                    orderDetails: orderDetails,
                    cookieQuantity: cookieQuantity,
                    cookieFlavor: cookieFlavor,
                    macaronQuantity: macaronQuantity,
                    macaronFlavor: macaronFlavor,
                    dueDate: formatDateForRequest(pickupDate),
                }
            )
        };

        fetch(process.env.REACT_APP_WAYWARD_BACKEND_URL + "/orders", requestOptions)
            .then(
                (result) => {
                    setShowFail(false);
                    setShowSuccess(true);
                },
                (error) => {
                    console.error(error);
                    setShowFail(true);
                    setShowSuccess(false);
                }
            )
            .finally(() => setLoading(false));
    }

    const validateInput = () => {
        const validations = [];

        if (firstName.trim() === "") {
            validations["firstName"] = "First Name is required.";
        }

        if (email.trim() === "" || !email.includes("@")) {
            validations["email"] = "Valid email address is required."
        }

        if (cookieQuantity) {
            if (cookieQuantity % 6 !== 0) {
                validations["cookieQuantity"] = "Increments of 6.";
            }
            if (cookieQuantity < 12) {
                validations["cookieQuantity"] = "Minimum order of 12.";
            }
        }

        if (macaronQuantity) {
            if (macaronQuantity % 6 !== 0) {
                validations["macaronQuantity"] = "Increments of 6.";
            }

            if (macaronQuantity < 6) {
                validations["macaronQuantity"] = "Minimum order of 6.";
            }
        }

        if (!pickupDate) {
            validations["orderDate"] = "Valid date is required.";
        }

        if (validations.length === 0) {
            if (!acceptedTerms) {
                validations["acceptedTerms"] = "You must accept the terms to submit an order.";
            }
        }

        setErrors(validations);
        return Object.keys(validations).length === 0;
    }

    return (
        <div className={"container"}>
            {!showFail && !showSuccess &&
                <form className="needs-validation py-5" onSubmit={handleFormSubmit} noValidate>
                    <div className="row pb-5">
                        <div className="col">
                            <p>
                                Submit the below form with a little info about your request, and we'll contact you
                                within
                                one business
                                day to provide an estimate and finalize your order.
                            </p>
                            <p>
                                Have questions? <Link to="/contact">Contact Us</Link>
                            </p>
                        </div>
                    </div>
                    <div className="row g-3 pb-3">
                        <div className="col-md">
                            <div className="form-floating">
                                <input id="firstName" type="text" className="form-control" value={firstName}
                                       onChange={(e) => setFirstName(e.target.value)} placeholder="First Name"/>
                                <label htmlFor="firstName">First Name</label>
                                {errors["firstName"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["firstName"]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-floating">
                                <input id="lastName" type="text" className="form-control" value={lastName}
                                       onChange={(e) => setLastName(e.target.value)} placeholder="Last Name"/>
                                <label htmlFor="lastName">Last Name</label>
                                {errors["lastName"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["lastName"]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 pb-3">
                        <div className="col-md-4">
                            <div className="form-floating">
                                <input id="phoneNumber" type="text" className="form-control" value={phoneNumber}
                                       onChange={handlePhoneNumber} placeholder="Phone Number"/>
                                <label htmlFor="phoneNumber">Phone Number</label>
                                {errors["phoneNumber"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["phoneNumber"]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-floating">
                                <input id="email" type="text" className="form-control" value={email}
                                       onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                                <label htmlFor="email">Email</label>
                                {errors["email"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["email"]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 pb-3">
                        <div className="col">
                            <div className="form-floating">
									<textarea id="orderDetails" className="form-control" style={{height: "200px"}}
                                              onChange={(e) => setOrderDetails(e.target.value)} value={orderDetails}
                                              placeholder="Order Details"/>
                                <label htmlFor="orderDetails">Order Details</label>
                                {errors["orderDetails"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["orderDetails"]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 pb-3">
                        <div className="col-md-6">
                            <DatePicker id="order-date" className="form-control" selected={pickupDate}
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeSelect
                                        onChange={(e) => setPickupDate(e)}
                                        customInput={<CustomDateInput/>}/>
                            {errors["orderDate"] &&
                                <div className="invalid-feedback d-block">
                                    {errors["orderDate"]}
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"row g-3 pb-3"}>
                        <div className="col-6 col-md-4">
                            <div className="form-floating">
                                <input id="cookieQuantity" type="text" className="form-control"
                                       value={cookieQuantity}
                                       onChange={(e) => setCookieQuantity(e.target.value)}
                                       placeholder="Cookie Quantity"/>
                                <label htmlFor="cookieQuantity">Cookie Quantity</label>
                                {errors["cookieQuantity"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["cookieQuantity"]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-6 col-md-5">
                            <div className="form-floating">
                                <select id="cookieFlavor" className="form-select" value={cookieFlavor}
                                        onChange={(e) => setCookieFlavor(e.target.value)}>
                                    <option value="">&nbsp;</option>
                                    <option value="VANILLA">Vanilla</option>
                                    <option value="LEMON">Lemon</option>
                                </select>
                                <label htmlFor="floatingSelect">Cookie Flavor</label>
                            </div>
                        </div>
                    </div>
                    <div className={"row g-3 pb-5"}>
                        <div className="col-6 col-md-4">
                            <div className="form-floating">
                                <input id="macaronQuantity" type="text" className="form-control"
                                       value={macaronQuantity}
                                       onChange={(e) => setMacaronQuantity(e.target.value)}
                                       placeholder="macaron Quantity"/>
                                <label htmlFor="macaronQuantity">Macaron Quantity</label>
                                {errors["macaronQuantity"] &&
                                    <div className="invalid-feedback d-block">
                                        {errors["macaronQuantity"]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-6 col-md-5">
                            <div className="form-floating">
                                <select id="macaronFlavor" className="form-select" value={macaronFlavor}
                                        onChange={(e) => setMacaronFlavor(e.target.value)}>
                                    <option value=""></option>
                                    <option value="VANILLA">Vanilla</option>
                                    <option value="LEMON">Lemon</option>
                                    <option value="STRAWBERRY_CHEESECAKE">Strawberry Cheesecake</option>
                                    <option value="SNICKERDOODLE">Snickerdoodle</option>
                                    <option value="CHOCOLATE">Chocolate</option>
                                </select>
                                <label htmlFor="floatingSelect">Macaron Flavor</label>
                            </div>
                        </div>
                    </div>
                    <div className={"row g-3 pb-3"}>
                        <div className="col d-flex justify-content-center">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={acceptedTerms}
                                       id="termsCheckbox" onChange={(e) => setAcceptedTerms(e.target.checked)}/>
                                <label className="form-check-label ms-2" htmlFor="termsCheckbox">
                                    By checking this box, you are agreeing to our{" "}
                                    <NavLink to={"/terms-and-conditions"} target={"_blank"}>
                                        terms and conditions.
                                    </NavLink>
                                </label>
                                {errors["acceptedTerms"] &&
                                    <div className="invalid-feedback d-flex justify-content-center">
                                        {errors["acceptedTerms"]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 pb-3 pt-4">
                        <div className="col text-center">
                            {loading ?
                                <img src={process.env.PUBLIC_URL + "/apple-touch-icon-120x120.png"} width={"90"}
                                     className={"loader"}
                                     alt="Wayward Cookies logo"/>
                                :
                                <button type="submit" className="btn btn-primary">Submit</button>
                            }
                        </div>
                    </div>
                </form>
            }
            {showFail &&
                <div className="row">
                    <div className="col d-flex align-items-center justify-content-center"
                         style={{minHeight: "400px"}}>
                        <p className={"text-center"}>
                            Something went wrong, please
                            <a href="mailto:&#099;&#104;&#114;&#105;&#115;&#116;&#105;&#110;&#097;&#064;&#119;&#097;&#121;&#119;&#097;&#114;&#100;&#099;&#111;&#111;&#107;&#105;&#101;&#115;&#046;&#099;&#111;&#109;">
                                email me
                            </a>.
                        </p>
                    </div>
                </div>
            }
            {showSuccess &&
                <div className="row">
                    <div className="col d-flex align-items-center justify-content-center"
                         style={{minHeight: "400px"}}>
                        <p className={"text-center"}>
                            Thank you for your order request! <br/>
                            <br/>
                            Please allow 24 hours for correspondence.
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}