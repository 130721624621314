import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import "./footer.css";
import {NavLink} from "react-router-dom";

export const Footer = () => {
    return (
        <div className={"container-fluid"}>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-4 d-flex align-items-center ps-4">
                    <span className="mb-3 mb-md-0 ">© {new Date().getFullYear()} Wayward Cookies&nbsp;
                        <NavLink to={"/login"} className={"blended-link"}>LLC</NavLink>
                    </span>
                </div>
                <div className="nav col-md-4 justify-content-end list-unstyled d-flex pe-4 social-media">
                    <div className="ms-3">
                        <a target={"_blank"} href="https://www.facebook.com/waywardcookies" className={"blended-link"}>
                            <FontAwesomeIcon icon={faFacebookSquare}/>
                        </a>
                    </div>
                    <div className="ms-3">
                        <a target={"_blank"} href="https://www.instagram.com/waywardcookies/"
                           className={"blended-link"}>
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}