import './App.css';
import {Header} from "./components/common/layout/Header";
import {Footer} from "./components/common/layout/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import {Home} from "./components/home/Home";
import {Contact} from "./components/contact/Contact";
import {FAQ} from "./components/faq/FAQ";
import {Gallery} from "./components/gallery/Gallery";
import {Shop} from "./components/shop/Shop";
import {CustomOrder} from "./components/custom-order/CustomOrder";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import {TermsAndConditions} from "./components/terms-and-conditions/termsAndConditions";
import {CookieCare} from "./components/care/cookieCare";

export const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <Header/>

                <Routes>
                    <Route path="/" element={<Home/>}>
                    </Route>
                    <Route path="/contact" element={<Contact/>}>
                    </Route>
                    <Route path="/faq" element={<FAQ/>}>
                    </Route>
                    <Route path="/gallery" element={<Gallery/>}>
                    </Route>
                    <Route path="/shop" element={<Shop/>}>
                    </Route>
                    <Route path="/custom-order" element={<CustomOrder/>}>
                    </Route>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}>
                    </Route>
                    <Route path="/cookie-care" element={<CookieCare/>}>
                    </Route>

                </Routes>

                <Footer/>
            </div>
        </BrowserRouter>
    )
}
