import {useEffect, useState} from "react";

export const CookieCare = () => {

    const [sugarCare, setSugarCare] = useState({});
    const [macaronCare, setMacaronCare] = useState({});

    useEffect(() => {
        fetch("https://strapi.waywardcookies.com/api/macaron-care?populate=*")
            .then(response => response.json())
            .then(resp => setMacaronCare(resp.data.Image.url))
            .catch(error => console.error("Error fetching data:", error));
        fetch("https://strapi.waywardcookies.com/api/sugar-cookie-care?populate=*")
            .then(response => response.json())
            .then(resp => setSugarCare(resp.data.Image.url))
            .catch(error => console.error("Error fetching data:", error));
        document.title = "Wayward Cookies : Cookie Care";
    }, []);

    return (
        <div className={"container py-5 text-center"}>
            <div className={"row"}>
                <div className={"col col-lg-8 mx-auto"}>
                    <img src={`https://strapi.waywardcookies.com${macaronCare}`}
                         alt={"Wayward Cookies Macaron Care"}
                         style={{width: "100%"}}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col col-lg-8 mx-auto"}>
                    <img src={`https://strapi.waywardcookies.com${sugarCare}`}
                         alt={"Wayward Cookies Sugar Cookie Care"}
                         style={{width: "100%"}}/>
                </div>
            </div>
        </div>
    )
}